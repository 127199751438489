import React from "react";
import { Grid } from "semantic-ui-react";
import Fade from "react-reveal/Fade";
// import One from "./one";
// import Two from "./two";
// import Three from "./three";
// import Four from "./four";
// import Five from "./five";
// import Six from "./six";
// import Seven from "./seven";
// import Eight from "./Eight";
import "./Projects.css";

const Projects = () => (
  <Fade big>
    <div className="content-projects">
      <h1 className="about">PROJECTS</h1>
      <Grid>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <h3 className="inProgress"> page in progress</h3>
        </Grid.Column>
        {/* <Grid.Column mobile={16} tablet={16} computer={8}>
          <One />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Six />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Five />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Four />
        </Grid.Column>

        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Three />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Two />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Eight />
        </Grid.Column>

        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Seven />
        </Grid.Column> */}
      </Grid>
    </div>
  </Fade>
);

export default Projects;
