import React from "react";
import { Form, Button } from "semantic-ui-react";
import Fade from "react-reveal/Fade";
import IconsSmall from "../IconsSmall";
import "./Contact.css";

class Contact extends React.Component {
  render() {
    return (
      <div>
        <Fade big>
          <div className="page">
            <h2 className="getintouch">Get in touch</h2>
            <div className="contact">
              <h3 className="contactdetails">email address - </h3>
              <p> dorotanowak@rocketmail.com </p>
              <h3 className="contactdetails">social media - </h3>
              <IconsSmall />
            </div>

            <Form
              className="contact-form"
              action="https://formspree.io/dorotanowak@rocketmail.com"
              method="POST"
            >
              <Form.Field>
                <label>Name</label>
                <input type="text" name="name" placeholder="Your Name" />
              </Form.Field>
              <Form.Field>
                <label>Your email</label>
                <input
                  type="email"
                  name="_replyto"
                  placeholder="Your email address"
                />
              </Form.Field>
              <Form.Field>
                <label>Your message</label>

                <textarea name="message" placeholder="Your Message" />
              </Form.Field>
              <Button
                className="sendbutton"
                color="black"
                type="submit"
                value="Send"
              >
                Send :)
              </Button>
            </Form>
          </div>
        </Fade>
      </div>
    );
  }
}

export default Contact;
