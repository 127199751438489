import React from "react";
import ToogleButton from "../ToggleButton/ToggleButton";
import { Link } from "react-router-dom";
import "./Nav.css";

const nav = props => (
  <div className="toolbar">
    <nav className="toolbar__navigation">
      <div className="toolbar__toggle-button">
        <ToogleButton click={props.clickHandler} />
      </div>
      <div className="toolbar_navigation-items">
        <ul>
          <li>
            <Link to="/home">home</Link>
          </li>
          <li>
            <Link to="/aboutme">about me</Link>
          </li>
          <li>
            {" "}
            <Link to="/projects">projects</Link>
          </li>
          <li>
            {" "}
            <Link to="/contact">contact</Link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
);

export default nav;
