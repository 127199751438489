import React from "react";
import linkedin from "./img/linkedinS.svg";
import fb from "./img/fbS.svg";
import insta from "./img/instaS.svg";
import github from "./img/githubS.svg";

const iconsBig = () => (
  <div className="box-aboutme">
    <div className="skills">Social media links</div>
    <div className="links">
      <a href="https://www.linkedin.com/in/dorotan">
        <img src={linkedin} alt="linkedin" />
      </a>

      <a href="https://www.instagram.com/dorotanowak/">
        <img src={insta} alt="insta" />
      </a>

      <a href="https://m.facebook.com/dorota.nowak.56">
        <img src={fb} alt="fb" />
      </a>

      <a href="https://github.com/dorotanowak1">
        <img src={github} alt="github" />
      </a>
    </div>
  </div>
);

export default iconsBig;
