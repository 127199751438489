import React from "react";
import "./Footer.css";

const Footer = () => (
  <div className="footer">
    &copy;
    {new Date().getFullYear()}. made by Dorota N.
  </div>
);

export default Footer;
