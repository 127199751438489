import React from "react";
import Fade from "react-reveal/Fade";
import javascript from "../img/javascript.svg";
import react from "../img/react.png";
import html from "../img/html.svg";
import css from "../img/css.svg";
import jquery from "../img/jquery.svg";
import photoshop from "../img/photoshop.svg";
import IconsBig from "../IconsBig";
import terminal from "../img/terminal.png";
import "./Aboutme.css";
import npm from "../img/npm.svg";
import git from "../img/git.svg";

const Aboutme = () => (
  <Fade big>
    <div className="about-me_page">
      <div className="aboutme_background">
        <h1 className="about">ABOUT</h1>
        <p className="about-me_text">
          Hello. My name is Dorota - creative and passionate front-end web
          developer. I create responsive websites and applications.
        </p>
      </div>
      <div className="box-aboutme">
        <div className="skills">Technical skills</div>
        <div className="links">
          <img src={html} alt="html" />

          <img src={css} alt="css" />

          <img src={javascript} alt="javascript" />

          <img src={jquery} alt="jquery" />

          <img src={react} alt="react" />

          <img src={photoshop} alt="photoshop" />
        </div>
      </div>
      <div className="section_background">
        <p className="about-me_text-second">
          WHY FRONT-END DEVELOPMENT ? <br />I love building things from scratch.
          I can create responsive websites & applications that work on a wide
          range of devices. My goal is to provide the best user experience and
          clean and funcional design.
        </p>
        {/* <div className="links">
          <img src={terminal} alt="terminal" />
          <img src={npm} alt="npm" />
          <img src={git} alt="git" />
        </div> */}
      </div>
      <div className="icons_backgroud">
        <IconsBig />
      </div>
    </div>
  </Fade>
);

export default Aboutme;
