import React from "react";
import "./App.css";
import Home from "./Header/Home";
import { Route, Redirect, Switch } from "react-router-dom";
import Aboutme from "./Aboutme/Aboutme";
import Contact from "./Contact/Contact";
import Projects from "./Projects/Projects";
import SideNav from "./SideNav/SideNav";
import Backdrop from "./Backdrop/Backdrop";
import Nav from "./Nav/Nav";
import Footer from "./Footer/Footer";

class App extends React.Component {
  state = {
    sidenavOpen: false
  };
  sidenavClickHandler = () => {
    this.setState(prevState => {
      return { sidenavOpen: !prevState.sidenavOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sidenavOpen: false });
  };

  render() {
    let backdrop;

    if (this.state.sidenavOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }

    return (
      <div style={{ height: "100%" }}>
        <Nav clickHandler={this.sidenavClickHandler} />
        <SideNav show={this.state.sidenavOpen} />
        {backdrop}

        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/aboutme" component={Aboutme} />
          <Route path="/contact" component={Contact} />
          <Route path="/projects" component={Projects} />
          <Redirect from="/" to="/home" />
        </Switch>

        <Footer />
      </div>
    );
  }
}

export default App;
