import React from "react";
import "./Home.css";
import IconsSmall from "../IconsSmall";

const home = () => (
  <div className="page">
    <div className="page_content">
      <h1 className="header_name">Hi, I am Dorota</h1>

      <div className="developer">
        <h2>front-end developer</h2>
        <p className="location">
          <img
            className="pin"
            src="http://www.clipartbest.com/cliparts/ncX/qyL/ncXqyLdcB.png"
            alt="pin"
          />
          London, UK
        </p>
      </div>
      <div className="icons">
        <IconsSmall />
      </div>
    </div>
  </div>
);

export default home;
